import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '@common/organisation.service';
import { RemitReportService } from '../remit-report.service';
import { takeUntil } from 'rxjs/operators';
import { ActivityReportModel } from '../model/activity-report.model';
import { DateUtils } from '@common/shared/date.utils';
import { BehaviorSubject, Subject } from 'rxjs';
import { OrganisationGroupModel } from '@common/organisation-group.model';
import { Column } from '@common/shared/datatable.model';

@Component({
  selector: 'cms-elcom-activity-report',
  templateUrl: './elcom-activity-report.component.html',
  styleUrl: './elcom-activity-report.component.scss'
})
export class ElComActivityReportComponent implements OnInit, OnDestroy {

  selection: ActivityReportModel
  organisationGroup: OrganisationGroupModel = { legalName: '', displayName: '' };
  rows = new BehaviorSubject<ActivityReportModel[]>([]);
  columns: Column[] = [
    { title: 'As of', name: 'asOfDate', style: { 'width': '85px' } },
    { title: 'Result', name: 'result', style: { 'width': '61px', 'text-align': 'center' } },
    { title: 'Executed', name: 'executionTimestamp', style: { 'width': '210px' } },
    { title: 'Exec. time (s)', name: 'executionDurationInSec', style: { 'text-align': 'right' } }
  ];

  private onDestroy$ = new Subject();

  constructor(private orgService: OrganisationService, private remitReportService: RemitReportService) {
  }

  ngOnInit() {
    this.remitReportService.getElComActivityReport().pipe(takeUntil(this.onDestroy$)).subscribe(report => {
      if (report) {

        const data: ActivityReportModel[] = report.map(rep => ({
          ...rep,
          asOfDate: DateUtils.transformDate(rep.asOfDate),
          executionTimestamp: DateUtils.transformDateWithTimestamp(rep.executionTimestamp)
        }));

        this.rows.next(data);
      }
    });

    this.orgService.getOrganisationGroup().pipe(takeUntil(this.onDestroy$)).subscribe(orgGroup => {
      this.organisationGroup = orgGroup;
      this.remitReportService.loadElComActivityReport(this.organisationGroup.id);
    });
  }

  ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
