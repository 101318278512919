import { BehaviorSubject, Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { OrganisationService } from '@common/organisation.service';
import { ActivityReportModel } from './model/activity-report.model';
import { RemitReconciliationReportModel } from './reconciliation/remit-reconciliation-report.model';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { OrganisationGroupModel } from '@common/organisation-group.model';
import { ApiRequestService } from '@common/api-request.service';
import { EmirReconciliationReportModel } from './emir/emir-reconciliation-report.component';
import { DateRangeFilter } from '@common/shared/shared.model';
import { Message } from 'primeng/api';
import { catchError, map } from 'rxjs/operators';
import { PresetValues } from './emir/emir-reconciliation-report-models';

@Injectable()
export class RemitReportService {

  remitActivityReport = new BehaviorSubject<ActivityReportModel[]>(null);
  elcomActivityReport = new BehaviorSubject<ActivityReportModel[]>(null);
  remitReconciliationReport = new BehaviorSubject<RemitReconciliationReportModel[]>(null);
  emirReconciliationReport = new BehaviorSubject<EmirReconciliationReportModel[]>(null);

  private message = new BehaviorSubject<Message>(null);
  private presetValues = new BehaviorSubject<PresetValues>({ uploadPanelVisible: false, rerunAllowed: false });

  emirReportApiRoute: string = '/api/err/emir/report';
  activityReportApi: string = '/api/err/remit/report/activity/';
  remitReconciliationReportApi: string = '/api/err/remit/report/reconciliation/';
  emirReconciliationReportApi: string = this.emirReportApiRoute + '/reconciliation/';

  orgGroup: OrganisationGroupModel;

  refreshEmirReconciliation = new BehaviorSubject<boolean>(false);
  uploading: boolean = false;

  constructor(private orgService: OrganisationService, private apiRequestService: ApiRequestService) {
    orgService.getOrganisationGroup().subscribe(orgGroup => {
      this.orgGroup = orgGroup;
    });

  }

  getRemitActivityReport(): Observable<ActivityReportModel[]> {
    return this.remitActivityReport.asObservable();
  }

  getElComActivityReport(): Observable<ActivityReportModel[]> {
    return this.elcomActivityReport.asObservable();
  }

  loadRemitActivityReport(orgGroupId: number) {
    if (orgGroupId) {
      this.apiRequestService.get(this.activityReportApi + orgGroupId).subscribe(
        response => {
          this.remitActivityReport.next(response.reports);
        });
    }
  }

  loadElComActivityReport(orgGroupId: number) {
    if (orgGroupId) {
      this.apiRequestService.get(this.activityReportApi + orgGroupId, { reportingTo: "elcom" }).subscribe(
        response => {
          this.elcomActivityReport.next(response.reports);
        });
    }
  }

  downloadActivityReport(activityReportId, reportsFor?: 'elcom' | null) {
    const orgGroupId = this.orgGroup.id;

    if (activityReportId && orgGroupId) {
      let params = new HttpParams().append('activityReportId', activityReportId.toString());

      if (reportsFor == 'elcom') {
        params = params.append('reportingTo', 'elcom');
      }
      const url = this.activityReportApi + orgGroupId + '/download';
      return this.apiRequestService.getBlob(url, null, params).pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
    }
  }

  downloadBillingReport(dateRangeModel: DateRangeFilter) {

    const orgGroupId = this.orgGroup.id;

    if (orgGroupId) {
      const url = '/api/reporting/err/billing/' + orgGroupId + '/excel';

      return this.apiRequestService.getBlob(url, dateRangeModel, null).pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
    }
  }

  getRemitReconciliationReport(): Observable<RemitReconciliationReportModel[]> {
    return this.remitReconciliationReport.asObservable();
  }

  loadRemitReconciliationReport(orgGroupId: number) {
    if (orgGroupId) {
      this.apiRequestService.get(this.remitReconciliationReportApi + orgGroupId, null).subscribe(
        response => {
          this.remitReconciliationReport.next(response.rows);
        }
      );
    }
  }

  loadEmirReconciliationReport(orgGroupId: number) {
    if (orgGroupId) {
      this.apiRequestService.get(this.emirReconciliationReportApi + orgGroupId, null).subscribe(
        response => {
          this.emirReconciliationReport.next(response.rows);
        }
      );
    }
  }

  refreshEmirReconciliationReport() {
    this.loadEmirReconciliationReport(this.orgGroup.id);
  }

  onRefreshEmirReconciliation(): Observable<boolean> {
    return this.refreshEmirReconciliation.asObservable();
  }

  setRefreshEmirReconciliation(refresh: boolean) {
    this.refreshEmirReconciliation.next(refresh);
  }

  downloadEmirReconciliationExcelReport(reconciliationReportId) {

    const orgGroupId = this.orgGroup.id;

    if (reconciliationReportId && orgGroupId) {
      const params = new HttpParams().append('reconciliationReportId', reconciliationReportId.toString());
      const url = this.emirReconciliationReportApi + orgGroupId + '/excel';

      return this.apiRequestService.getBlob(url, null, params).pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
    }
  }

  loadEmirPresetValues(): void {
    this.apiRequestService.get(this.emirReportApiRoute + '/reconciliation/loadPresetValues').pipe(
      map((response: any) => response.value)).subscribe(value => {
      this.presetValues.next(value);
    });
  }

  onEmirPresetValuesChanged(): Observable<PresetValues> {
    return this.presetValues.asObservable();
  }

  getEmirReconciliationReport(): Observable<EmirReconciliationReportModel[]> {
    return this.emirReconciliationReport.asObservable();
  }

  rerunEmirReport(isoDate: string): Observable<any> {

    if (isoDate) {
      const params = new HttpParams().append('asOfDate', isoDate);

      return this.apiRequestService.get(this.emirReportApiRoute + '/reconciliation/rerun/execute', params);
    }
  }

  downloadExcelReconciliationReport(reconciliationReportId) {

    const orgGroupId = this.orgGroup.id;

    if (reconciliationReportId && orgGroupId) {
      const params = new HttpParams().append('reconciliationReportId', reconciliationReportId.toString());
      const url = this.remitReconciliationReportApi + orgGroupId + '/excel';

      return this.apiRequestService.getBlob(url, null, params).pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
    }
  }

  getRemitReconciliationRetentionRuns(): Observable<string> {

    const result = new Subject<string>();

    this.apiRequestService.get(this.emirReconciliationReportApi + 'retentionRuns', null).subscribe(httpResponse => {
      result.next(httpResponse.response.toString());
    });

    return result.asObservable();
  }

  private getFilenameFromHeader(headers): string {
    const contentDisposition = headers.get('content-disposition');
    return contentDisposition.split(';')[1].trim().split('=')[1];
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
    const errMsg = (error.message) ? error.message : 'Server error';
    return observableThrowError(errMsg);
  }

  public setMessage(message: Message) {
    this.message.next(message);
  }

  public onMessageChange(): Observable<Message> {
    return this.message.asObservable();
  }

  uploadEmirCsvFile(csvFile: File) {
    this.setMessage(null);

    if (this.validateEmirCsvFile(csvFile) && !this.uploading) {
      this.uploading = true;
      const formData = new FormData();
      formData.append('file', csvFile);

      this.apiRequestService.post(this.emirReportApiRoute + '/upload', formData).subscribe(response => {
        if (response.validationFailureMessages) {
          this.uploading = false;
          for (let i = 0; i < response.validationFailureMessages.length; i++) {
            this.setMessage({ severity: 'error', summary: '', detail: response.validationFailureMessages[i] });
          }
        } else {
          this.uploading = false;
          this.setMessage({ severity: 'success', summary: '', detail: response.persistedRecords + '  trades uploaded.' });
          this.setRefreshEmirReconciliation(true);
        }
      }, error => {
        this.uploading = false;
        this.setMessage({ severity: 'error', summary: '', detail: error.statusText });
      });
    }
  }

  downloadFormatInfoFile(): Promise<{ filename: string, blob: Blob }> {
    return this.apiRequestService.getBlob(this.emirReconciliationReportApi + 'download/PortRec-Upload-Format.xlsx', null, null).pipe(map((response: HttpResponse<Blob>) => {
      const filename = this.getFilenameFromHeader(response.headers);
      const blob: Blob = response.body;
      return { filename, blob };
    }), catchError(this.handleError)).toPromise();
  }

  downloadTemplateFile(): Promise<{ filename: string, blob: Blob }> {
    return this.apiRequestService.getBlob(this.emirReconciliationReportApi + 'download/PortRec-Upload-Sample.csv', null, null).pipe(map((response: HttpResponse<Blob>) => {
      const filename = this.getFilenameFromHeader(response.headers);
      const blob: Blob = response.body;
      return { filename, blob };
    }), catchError(this.handleError)).toPromise();
  }

  public validateEmirCsvFile(file: File): boolean {
    let valid: boolean = true;
    // if (file) {
    //     if (file.name.length > 100) {
    //         valid = false;
    //         this.setMessage({ severity: 'error', summary: 'Error', detail: 'File name length over limit.' });
    //     }
    //
    const sizeInMB = parseFloat(((file.size / 1000) / 1024).toFixed(2));

    if (sizeInMB > 10) {
      valid = false;
      this.setMessage({
        severity: 'error',
        summary: '',
        detail: 'File size must not exceed 10 MB. Please split your data but ensure that records with identical PARTY1_CODE be kept together in one file.'
      });
    }

    if (!file.name.endsWith('.csv')) {
      valid = false;
      this.setMessage({ severity: 'error', detail: 'Please choose a .csv file.' });
    }

    return valid;
  }

}
