import { ErrorResponse } from '@common/error-response.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';
import { MyFilter } from '@common/my-filters/my-filters.model';
import { HiddenDeals, Note } from '@common/deals/deals.model';

export interface EcmDealFinder {
  agreement: string;
  auditGroupId: number;
  broker: string;
  brokerFee: string;
  brokerFeeCurrency: string;
  brokerNearMatcherButtonVisible: string;
  brokerState: string;
  brokerTradeId: string;
  buyerParty: string;
  commodity: string;
  counterParty: string;
  counterPartyDisplayName: string;
  counterPartyNearMatcherButtonVisible: boolean;
  counterpartyTradeId: string;
  currency: string;
  deliveryEnd: string;
  deliveryPoint: string;
  deliveryStart: string;
  dfHedgingExemption: string;
  dfReportingParty: string;
  dfRepository: string;
  dfUsi: string;
  documentId: string;
  documentStoreId: string;
  documentType: string;
  documentVersion: number;
  ecmLightAmendmentButtonVisible: boolean;
  ecmLightCancelButtonVisible: boolean;
  effectiveDate: string;
  euReportingBrokerUti: string;
  euReportingUti: string;
  formulaId: string;
  hidden: boolean;
  hideCheckboxVisible: boolean;
  hideReason: string;
  id: number;
  indexCommodity: string;
  market: string;
  matchHash: number;
  notes: Note[];
  partyType: string;
  price: number;
  priceUnit: string;
  receiverId: string;
  sellerParty: string;
  senderId: string;
  state: string;
  submissionDate: string;
  submissionTimestamp: string;
  terminationDate: string;
  terminationReason: string;
  traderName: string;
  totalContractValue: number;
  totalVolume: number;
  totalVolumeUnit: string;
  tradeDate: string;
  tradeId: string;
  transactionType: string;
}


export interface EcmDealFinderResponse extends ErrorResponse{
  columnOrder: string[];
  count: number;
  currentPage: number;
  values: EcmDealFinder[];
  notFoundIds?: string[];
}

export interface DealFinderFilters {
  ourTradeId?: string;
  theirTradeId?: string;
  documentId?: string;
  properties?: DealFinderPropertiesFilters;
  filterBy?: FilterBy;
}

export interface DealFinderIdFilters {
  ourTradeId?: string;
  theirTradeId?: string;
  documentId?: string;
}

export interface FilterByIdRequest {
  searchValue?: string;
  paging?: PagingFilter;
  sorting?: SortingFilter;
}

export interface FilterByPropertiesRequest {
  brokers: string[];
  commodities: string[];
  counterParties: string[];
  markets: string[];
  transactionTypes: [];
  deliveryEndDate: string;
  deliveryStartDate: string;
  hiddenDeals: string;
  price: number;
  senderType: string;
  submissionDateRange: DateRange;
  tradeDateRange: DateRange;
  paging?: PagingFilter;
  sorting?: SortingFilter;
}

export interface EcmPropertyFiltersValues {
  broker: string[];
  commodities: string[];
  counterParties: string[];
  markets: string[];
  transactionTypes: string[];
  tradeDate?: DateRange;
  submissionDate?: DateRange;
  deliveryPeriod?: DateRange;
}

export interface DealFinderPropertiesFilters {
  brokers: string[];
  commodities: string[];
  counterParties: string[];
  markets: string[];
  transactionTypes: string[];
  deliveryStartDate: string;
  deliveryEndDate: string;
  hiddenDeals: HiddenDeals;
  price: number;
  senderType: string;
  submissionDateRange: DateRange;
  tradeDateRange: DateRange;
}

export interface DateRange {
  end: string | Date;
  start: string | Date;
}

export interface EcmPropertyFiltersValuesResponse
  extends EcmPropertyFiltersValues, ErrorResponse { }

export type FilterBy = 'ourTradeId' | 'theirTradeId' | 'documentId' | 'dealProperties';

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export interface EcmMyFilter extends MyFilter {
  brokerFilter: string[];
  commodityFilter: string[];
  counterpartyFilter: string[];
  hiddenType: HiddenDeals;
  marketFilter: string[];
  transactionTypeFilter: string[];
}

export interface EcmMyFiltersResponse extends ErrorResponse {
  defaultFilter: EcmMyFilter;
  filterSelections: {[key: string]: EcmMyFilter };
}

export interface DeleteMyFilterResponse extends ErrorResponse {
  value: EcmMyFilter;
}

export interface TerminateDealRequest {
  reason: string;
  confirmationIds: number[];
}
export interface TerminateDealResponseModel extends ErrorResponse {
 value: {
   successList: number[];
  failureList: number[];
 };
}

export const ecmDealsAllColumnsConfig: Column[] = [
  { name: 'documentHidden', type: 'document-hidden', title: 'Hidden', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'hideReason', type: 'text', title: 'Hide Reason', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'submissionTimestamp', type: 'date', title: 'Submitted', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'documentId', type: 'text', title: 'Document ID', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'euReportingUti', type: 'text', title: '(EUR) UTI', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'documentType', type: 'text', title: 'Type', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'tradeId', type: 'text', title: 'Trade ID', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'tradeDate', type: 'dateWithoutTime', title: 'Trade Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'counterParty', type: 'text', title: 'Counterparty', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'counterPartyDisplayName', type: 'text', title: 'CP Display Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'broker', type: 'text', title: 'Broker', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'state', type: 'text', title: 'State', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'brokerState', type: 'text', title: 'Broker State', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'openDocument', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'auditTrail', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'notes', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'counterpartyNearMatch', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'brokerNearMatch', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'partyType', type: 'text', title: 'B/S', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'buyerParty', type: 'text', title: 'Buyer EIC', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'transactionType', type: 'text', title: 'Trans Type', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'agreement', type: 'text', title: 'Agreement', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'commodity', type: 'text', title: 'Commodity', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'market', type: 'text', title: 'Market', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'deliveryPoint', type: 'text', title: 'Delivery Point', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'deliveryStart', type: 'dateWithoutTime', title: 'Delivery Start', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'deliveryEnd', type: 'dateWithoutTime', title: 'Delivery End', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'totalVolume', type: 'currency', title: 'Volume', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'totalVolumeUnit', type: 'text', title: 'Vol Unit', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'price', type: 'currency', title: 'Price', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'priceUnit', type: 'text', title: 'Price Unit', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'totalContractValue', type: 'currency', title: 'Total Value', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'currency', type: 'text', title: 'Curr', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'submissionDate', type: 'dateWithoutTime', title: 'Sub Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'cancelDeal', type: 'button', title: 'eCM lite', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'openDocumentXml', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'documentVersion', type: 'text', title: 'Version', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'receiverId', type: 'text', title: 'Receiver EIC', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'effectiveDate', type: 'dateWithoutTime', title: 'Effective Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'dfHedgingExemption', type: 'text', title: '(DF) Hedging Exemption', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'dfReportingParty', type: 'text', title: '(DF) Rep. Party', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'dfUsi', type: 'text', title: '(DF) USI', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'euReportingBrokerUti', type: 'text', title: '(EUR) Broker UTI', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'brokerFeeForView', type: 'currency', title: 'Broker Fee', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'brokerFeeCurrencyForView', type: 'text', title: 'Fee Curr', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'brokerTradeId', type: 'text', title: 'Broker Trade ID', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'counterpartyTradeId', type: 'text', title: 'Counterparty Trade ID', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'formulaId', type: 'text', title: 'Formula ID', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'indexCommodity', type: 'text', title: 'Index Commodity', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'terminationReason', type: 'text', title: 'Reason for Match Termination', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'traderName', type: 'text', title: 'Trader Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'sellerParty', type: 'text', title: 'Seller EIC', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'senderId', type: 'text', title: 'Sender EIC', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'terminationDate', type: 'dateWithoutTime', title: 'Termination Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'dfRepository', type: 'text', title: '(DF) Repository', style: { ...defaultStyles }, sortable: true, visible: true },
];

export const ecmDealFinderCustomizeConfig: { [name: string]: Column } = {
  submissionDate: { name: 'submissionDate', title: 'Submission Date' },
  cancelDeal: { name: 'cancelDeal', title: 'Button "eCM lite Controls"' },
  openDocumentXml: { name: 'openDocumentXml', title: 'Button "Document (XML)', },
  documentVersion: { name: 'documentVersion', title: 'Document Version', },
  receiverId: { name: 'receiverId', title: 'Receiver EIC', },
  effectiveDate: { name: 'effectiveDate', title: 'Effective Date' },
  dfHedgingExemption: { name: 'dfHedgingExemption', title: '(Dodd-Frank) Hedging Exemption', },
  dfReportingParty: { name: 'dfReportingParty', title: '(Dodd-Frank) Reporting Party', },
  dfUsi: { name: 'dfUsi', title: '(Dodd-Frank) USI', },
  euReportingBrokerUti: { name: 'euReportingBrokerUti', title: '(EUReporting) Broker UTI' },
  brokerFeeForView: { name: 'brokerFeeForView', title: 'Broker Fee', style: { ...defaultStyles } },
  brokerFeeCurrencyForView: { name: 'brokerFeeCurrencyForView', title: 'Broker Fee Currency' },
  brokerTradeId: { name: 'brokerTradeId', title: 'Broker Trade ID', style: { ...defaultStyles } },
  counterpartyTradeId: { name: 'counterpartyTradeId', title: 'Counterparty Trade ID' },
  formulaId: { name: 'formulaId', title: 'Formula ID' },
  indexCommodity: { name: 'indexCommodity', title: 'Index Commodity' },
  terminationReason: { name: 'terminationReason', title: 'Reason for Match Termination' },
  traderName: { name: 'traderName', title: 'Trader Name' },
  sellerParty: { name: 'sellerParty', title: 'Seller EIC' },
  senderId: { name: 'senderId', title: 'Sender EIC' },
  terminationDate: { name: 'terminationDate', title: 'Termination Date' },
  dfRepository: { name: 'dfRepository', title: '(Dodd-Frank) First Reported Repository' },
  documentType: { name: 'documentType', title: 'Document Type' },
  tradeId: { name: 'tradeId', title: 'Trade ID' },
  tradeDate: { name: 'tradeDate', title: 'Trade Date' },
  hideReason: { name: 'hideReason', title: 'Hide Reason' },
  submissionTimestamp: { name: 'submissionTimestamp', title: 'Submitted (Timestamp)' },
  documentId: { name: 'documentId', title: 'Document ID' },
  euReportingUti: { name: 'euReportingUti', title: '(EUReporting) UTI' },
  counterParty: { name: 'counterParty', title: 'Counterparty EIC' },
  documentHidden: { name: 'documentHidden', title: 'Hide Indicator' },
  counterPartyDisplayName: { name: 'counterPartyDisplayName', title: 'Counterparty Display Name' },
  broker: { name: 'broker', title: 'Broker EIC' },
  state: { name: 'state', title: 'State' },
  brokerState: { name: 'brokerState', title: 'Broker State' },
  openDocument: { name: 'openDocument', title: 'Button "Document"' },
  auditTrail: { name: 'auditTrail', title: 'Button "Audit Trail"' },
  notes: { name: 'notes', title: 'Button "Notes"' },
  counterpartyNearMatch: { name: 'counterpartyNearMatch', title: 'Button "Counterparty Near Match"' },
  brokerNearMatch: { name: 'brokerNearMatch', title: 'Button "Broker Near Match"' },
  partyType: { name: 'partyType', title: 'B/S (Buy or Sell)' },
  buyerParty: { name: 'buyerParty', title: 'Buyer EIC' },
  transactionType: { name: 'transactionType', title: 'Transaction Type' },
  agreement: { name: 'agreement', title: 'Agreement' },
  commodity: { name: 'commodity', title: 'Commodity' },
  market: { name: 'market', title: 'Market' },
  deliveryPoint: { name: 'deliveryPoint', title: 'Delivery Point EIC' },
  deliveryStart: { name: 'deliveryStart', title: 'Delivery Start Date' },
  deliveryEnd: { name: 'deliveryEnd', title: 'Delivery End Date' },
  totalVolume: { name: 'totalVolume', title: 'Total Volume' },
  totalVolumeUnit: { name: 'totalVolumeUnit', title: 'Volume Unit' },
  price: { name: 'price', title: 'Price' },
  priceUnit: { name: 'priceUnit', title: 'Price Unit' },
  totalContractValue: { name: 'totalContractValue', title: 'Total Contract Value' },
  currency: { name: 'currency', title: 'Currency' },
};
