<cms-info ngPreserveWhitespaces>
  <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
  __What is the ElCom Activity Report?__

  This is a report of traffic between eRR and ElCom which details:
  * which **trades and orders** (of market participants **within your organisation group**) were **reported to ElCom** during the previous day

  The previous day is defined in terms of UK local time (0:00:00 to 24:00:00).

  The report is executed daily (MON-SUN) before start of business day.

  Reports are available for download for 31 days.

  Please note that the ElCom Activity Report does not include response tabs since unlike ARIS, ElCom does not send responses to indicate if a report has been accepted or rejected.

  **Included Legal Entities**

  The "Report Level" setting chosen by your organisation group determines which organisations in your group are included in the report
  * Option "Group" (default): all legal entities are included in one report
  * Option "Organisation": separate reports are generated for each legal entity
  <!--@formatter:on-->
</cms-info>
<div style="max-width: 500px">
  <p-table [value]="rows | async" selectionMode="single" [(selection)]="selection">
    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of columns">
          <th [style]="col.style">
            {{ col.title }}
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr [pSelectableRow]="row">
        <ng-container *ngFor="let col of columns">
          <td *ngIf="col.name == 'result'" [ngStyle]="col.style">
            <cms-table-button [activityReportId]="row.id" name="elcom" title="Zipped CSV"></cms-table-button>
          </td>
          <td *ngIf="col.name != 'result'" [ngStyle]="col.style">
            <span class="truncated-text">{{ row[col.name] }}</span>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>
