import { NgModule } from '@angular/core';
import { CmsCommonModule } from '@common/cms-common.module';

import { RemitReportComponent } from './remit-report.component';
import { RemitActivityReportComponent } from './activity/remit-activity-report.component';
import { RemitReportService } from './remit-report.service';
import { RemitReconciliationReportComponent } from './reconciliation/remit-reconciliation-report.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { EmirReconciliationReportComponent } from './emir/emir-reconciliation-report.component';
import { RemitBillingReportComponent } from './billing/remit-billing-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmirReconciliationReportUploadPanelComponent } from './emir/emir-reconciliation-report-upload-panel.component';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { BlockUIModule } from 'primeng/blockui';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { CalendarModule } from 'primeng/calendar';
import { TableButtonComponent } from './table-button/table-button.component';
import { ElComActivityReportComponent } from './elcom-activity/elcom-activity-report.component';

@NgModule({
  imports: [
    CmsCommonModule,
    TabViewModule,
    TableModule,
    FormsModule, ReactiveFormsModule,
    ButtonModule,
    BrowserModule,
    BlockUIModule,
    BrowserAnimationsModule,
    DialogModule,
    CalendarModule,
    ProgressSpinnerModule,
    MessagesModule,
    MessageModule,
    ProgressSpinnerModule,
    TableModule
  ],
  declarations: [
    RemitReportComponent,
    RemitActivityReportComponent,
    RemitReconciliationReportComponent,
    EmirReconciliationReportComponent,
    RemitBillingReportComponent,
    EmirReconciliationReportUploadPanelComponent,
    TableButtonComponent,
    ElComActivityReportComponent
  ],
  providers: [RemitReportService]
})
export class ReportModule {
}
