<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<p-tabView [activeIndex]="activeIndex | async" (onChange)="handleChange($event)">
  <p-tabPanel header="REMIT Activity">
    <cms-headline [pageTitle]="'REMIT Activity Report'" [displayOrganisationGroupName]="true"></cms-headline>
    <cms-remit-activity-report></cms-remit-activity-report>
  </p-tabPanel>

  <p-tabPanel header="ElCom Activity">
    <cms-headline [pageTitle]="'ElCom Activity Report'" [displayOrganisationGroupName]="true"></cms-headline>
    <cms-elcom-activity-report></cms-elcom-activity-report>
  </p-tabPanel>

  <p-tabPanel header="REMIT Reconciliation">
    <ng-template pTemplate="content">
      <cms-headline [pageTitle]="'REMIT Reconciliation Report'" [displayOrganisationGroupName]="true"></cms-headline>
      <cms-remit-reconciliation-report></cms-remit-reconciliation-report>
    </ng-template>
  </p-tabPanel>

  <p-tabPanel *ngIf="isRefitInactive$ | async" header="EMIR Reconciliation">
    <ng-template pTemplate="content">
      <cms-headline [pageTitle]="'EMIR Reconciliation Report'" [displayOrganisationGroupName]="true"></cms-headline>
      <div class="tab-view-body">

        <cms-emir-reconciliation-report></cms-emir-reconciliation-report>
      </div>
    </ng-template>
  </p-tabPanel>

  <p-tabPanel header="Billing" *ngIf="billingVisible">
    <cms-headline [pageTitle]="'eRR Billing'"></cms-headline>
    <div class="tab-view-body">
      <cms-remit-billing-report></cms-remit-billing-report>
    </div>
  </p-tabPanel>
</p-tabView>

</ng-template>
