import { Column } from '@common/shared/datatable.model';
import { ErrorResponse } from '@common/error-response.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { HiddenDeals, Note } from '@common/deals/deals.model';
import { MY_FILTERS_DIALOGS } from '@common/my-filters/my-filters.model';
import { Input } from '@angular/core';

export type Ecm_View_By_Values = 'TRADE_DATE' | 'SUBMISSION_DATE';
export type Ecm_Dashboard_Categories = 'IN_PROGRESS' | 'ISSUE' | 'COMPLETE';
export type DIALOGS = MY_FILTERS_DIALOGS | 'TERMINATE_DEAL' | 'ECM_LITE_CANCEL' | 'ADD_NOTES' | 'VIEW_NOTES';

export const EcmDashboardCategoriesMap: { [key: string]: Ecm_Dashboard_Categories } = {
  'inprogress': 'IN_PROGRESS',
  'issue': 'ISSUE',
  'complete': 'COMPLETE',
};

export const EcmDashboardTabs = [
  'inprogress',
  'issue',
  'complete'
];

export const TabToTitleMap = {
  'inprogress': 'Dashboard In Progress Page',
  'issue': 'Dashboard Issue Page',
  'complete': 'Dashboard Complete Page'
};

export const EcmDashboardCategoriesToLabelMap = {
  IN_PROGRESS: 'inprogress',
  ISSUE: 'issue',
  COMPLETE: 'complete',
};

export interface EcmDashboardPropertiesFilters {
  brokers: string[];
  commodities: string[];
  counterParties: string[];
  markets: string[];
  transactionTypes: string[];
  hiddenDeals: HiddenDeals;
}

export interface EcmBuckectsRequest {
  broker: string[];
  category: Ecm_Dashboard_Categories;
  commodity: string[];
  counterParty: string[];
  hiddenDeals: HiddenDeals;
  market: string[];
  transactionType: string[];
  viewBy: 'TRADE_DATE' | 'SUBMISSION_DATE';
}

export interface EcmBuckectsResponse extends ErrorResponse {
  bucketNames: string[];
  stateNames: string[];
  rows: EcmBuckectsRows[];
}

export interface EcmBucketsTableRows extends EcmBucketValues {
  state: string;
}

export interface EcmBuckectsRows {
  state: string;
  bucketValues: EcmBucketValues;
}

export interface EcmBucketValues {
  TOTAL: number;
  RANGE2: number;
  RANGE1: number;
  BEFORE_TODAY: number;
  RANGE3: number;
  TODAY: number;
  RANGE4_7: number;
  RANGE31_90: number;
  RANGE8_30: number;
}

export interface EcmDashboardDealsResponse
  extends ErrorResponse, EcmDashboardDealData {}

export interface EcmDashboardDealData {
  columnOrder: string[];
  count: number;
  currentPage: number;
  values: EcmDealItemModel[];
  dateRangeText: string;
}

export interface EcmDashboardDealItemModel {
  agreement: string;
  auditGroupId: number;
  broker: string;
  brokerFee: string;
  brokerFeeCurrency: string;
  brokerNearMatcherButtonVisible: string;
  brokerState: string;
  brokerTradeId: string;
  buyerParty: string;
  commodity: string;
  counterParty: string;
  counterPartyDisplayName: string;
  counterPartyNearMatcherButtonVisible: boolean;
  counterpartyTradeId: string;
  currency: string;
  deliveryEnd: string;
  deliveryPoint: string;
  deliveryStart: string;
  dfHedgingExemption: string;
  dfReportingParty: string;
  dfRepository: string;
  dfUsi: string;
  documentId: string;
  documentStoreId: string;
  documentType: string;
  documentVersion: number;
  ecmLightAmendmentButtonVisible: boolean;
  ecmLightCancelButtonVisible: boolean;
  effectiveDate: string;
  euReportingBrokerUti: string;
  euReportingUti: string;
  formulaId: string;
  hidden: boolean;
  hideCheckboxVisible: boolean;
  hideReason: string;
  id: number;
  indexCommodity: string;
  market: string;
  matchHash: number;
  notes: Note[];
  partyType: string;
  price: number;
  priceUnit: string;
  receiverId: string;
  sellerParty: string;
  senderId: string;
  state: string;
  submissionDate: string;
  submissionTimestamp: string;
  terminateDealButtonVisible: boolean;
  terminationDate: string;
  terminationReason: string;
  totalContractValue: number;
  totalVolume: number;
  totalVolumeUnit: string;
  tradeDate: string;
  tradeId: string;
  traderName: string;
  transactionType: string;
}

export interface GetEcmDashboardDealsRequest {
  filterRequestModel: EcmBuckectsRequest;
  paging: PagingFilter;
  sorting: SortingFilter;
  state: string;
  dateRange: string;
}

export const defaultStyles = {
  'width': '150px',
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export const allColumnsDealConfig: Column[] = [
  {
    name: 'documentHidden',
    type: 'document-hidden',
    title: 'Hidden',
    style: { ...defaultStyles, width: '70px' },
    sortable: false,
    visible: true
  },
  { name: 'hideReason', type: 'text', title: 'Hide Reason', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'submissionTimestamp', type: 'date', title: 'Submitted', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'documentId', type: 'text', title: 'Document ID', style: { ...defaultStyles, width: '430px' }, sortable: true, visible: true },
  { name: 'euReportingUti', type: 'text', title: '(EUR) UTI', style: { ...defaultStyles, width: '800px' }, sortable: true, visible: true },
  { name: 'documentType', type: 'text', title: 'Type', style: { ...defaultStyles, width: '68px' }, sortable: true, visible: true },
  { name: 'tradeId', type: 'text', title: 'Trade ID', style: { ...defaultStyles, width: '152px' }, sortable: true, visible: true },
  {
    name: 'tradeDate',
    type: 'dateWithoutTime',
    title: 'Trade Date',
    style: { ...defaultStyles, width: '112px' },
    sortable: true,
    visible: true
  },
  { name: 'counterParty', type: 'text', title: 'Counterparty', style: { ...defaultStyles, width: '132px' }, sortable: true, visible: true },
  { name: 'counterPartyDisplayName', type: 'text', title: 'CP Display Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'broker', type: 'text', title: 'Broker', style: { ...defaultStyles, width: '80px' }, sortable: true, visible: true },
  { name: 'state', type: 'text', title: 'State', style: { ...defaultStyles, width: '80px' }, sortable: true, visible: true },
  { name: 'brokerState', type: 'text', title: 'Broker State', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'terminateDeal', type: 'button', title: '', style: { ...defaultStyles, width: '45px' }, sortable: false, visible: false },
  { name: 'openDocument', type: 'button', title: '', style: { ...defaultStyles, width: '45px' }, sortable: false, visible: true },
  { name: 'auditTrail', type: 'button', title: '', style: { ...defaultStyles, width: '45px' }, sortable: false, visible: true },
  { name: 'notes', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true },
  { name: 'counterpartyNearMatch', type: 'button', title: '', style: { ...defaultStyles, width: '60px' }, sortable: false, visible: true },
  { name: 'brokerNearMatch', type: 'button', title: '', style: { ...defaultStyles, width: '60px' }, sortable: false, visible: true },
  { name: 'partyType', type: 'text', title: 'B/S', style: { ...defaultStyles, width: '55px' }, sortable: true, visible: true },
  { name: 'buyerParty', type: 'text', title: 'Buyer EIC', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'transactionType', type: 'text', title: 'Trans Type', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'traderName', type: 'text', title: 'Trader Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'agreement', type: 'text', title: 'Agreement', style: { ...defaultStyles, width: '112px' }, sortable: true, visible: true },
  { name: 'commodity', type: 'text', title: 'Commodity', style: { ...defaultStyles, width: '110px' }, sortable: true, visible: true },
  { name: 'market', type: 'text', title: 'Market', style: { ...defaultStyles, width: '80px' }, sortable: true, visible: true },
  { name: 'deliveryPoint', type: 'text', title: 'Delivery Point', style: { ...defaultStyles }, sortable: true, visible: true },
  {
    name: 'deliveryStart',
    type: 'dateWithoutTime',
    title: 'Delivery Start',
    style: { ...defaultStyles, width: '112px' },
    sortable: true,
    visible: true
  },
  {
    name: 'deliveryEnd',
    type: 'dateWithoutTime',
    title: 'Delivery End',
    style: { ...defaultStyles, width: '112px' },
    sortable: true,
    visible: true
  },
  { name: 'totalVolume', type: 'currency', title: 'Volume', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'totalVolumeUnit', type: 'text', title: 'Vol Unit', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'price', type: 'currency', title: 'Price', style: { ...defaultStyles, width: '68px', 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'priceUnit', type: 'text', title: 'Price Unit', style: { ...defaultStyles, width: '100px' }, sortable: true, visible: true },
  { name: 'totalContractValue', type: 'currency', title: 'Total Value', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'currency', type: 'text', title: 'Curr', style: { ...defaultStyles, width: '65px' }, sortable: true, visible: true },
  {
    name: 'submissionDate',
    type: 'dateWithoutTime',
    title: 'Sub Date',
    style: { ...defaultStyles, width: '112px' },
    sortable: true,
    visible: true
  },
  { name: 'cancelDeal', type: 'button', title: 'eCM lite', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'openDocumentXml', type: 'button', title: '', style: { ...defaultStyles, width: '45px' }, sortable: false, visible: true },
  {
    name: 'documentVersion',
    type: 'text',
    title: 'Version',
    style: { ...defaultStyles, 'text-align': 'right', width: '88px' },
    sortable: true,
    visible: true
  },
  { name: 'receiverId', type: 'text', title: 'Receiver EIC', style: { ...defaultStyles }, sortable: true, visible: true },
  {
    name: 'effectiveDate',
    type: 'dateWithoutTime',
    title: 'Effective Date',
    style: { ...defaultStyles, width: '112px' },
    sortable: true,
    visible: true
  },
  { name: 'dfHedgingExemption', type: 'text', title: '(DF) Hedging Exemption', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'dfReportingParty', type: 'text', title: '(DF) Rep. Party', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'dfUsi', type: 'text', title: '(DF) USI', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'euReportingBrokerUti', type: 'text', title: '(EUR) Broker UTI', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'brokerFeeForView', type: 'currency', title: 'Broker Fee', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'brokerFeeCurrencyForView', type: 'text', title: 'Fee Curr', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'brokerTradeId', type: 'text', title: 'Broker Trade ID', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'counterpartyTradeId', type: 'text', title: 'Counterparty Trade ID', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'formulaId', type: 'text', title: 'Formula ID', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'indexCommodity', type: 'text', title: 'Index Commodity', style: { ...defaultStyles }, sortable: true, visible: true },
  {
    name: 'terminationReason',
    type: 'text',
    title: 'Reason for Match Termination',
    style: { ...defaultStyles, width: '250px' },
    sortable: true,
    visible: true
  },
  { name: 'sellerParty', type: 'text', title: 'Seller EIC', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'senderId', type: 'text', title: 'Sender EIC', style: { ...defaultStyles }, sortable: true, visible: true },
  {
    name: 'terminationDate',
    type: 'dateWithoutTime',
    title: 'Termination Date',
    style: { ...defaultStyles, width: '112px' },
    sortable: true,
    visible: true
  },
  { name: 'dfRepository', type: 'text', title: '(DF) Repository', style: { ...defaultStyles }, sortable: true, visible: true },
];

export enum EcmStatesEnum {
  TRADER_IN_PROGRESS_PENDING = 'TRADER_IN_PROGRESS_PENDING',
  TRADER_IN_PROGRESS_ALLEGED = 'TRADER_IN_PROGRESS_ALLEGED',
  TRADER_IN_PROGRESS_PENDING_BR = 'TRADER_IN_PROGRESS_PENDING_BR',
  TRADER_IN_PROGRESS_ALLEGED_BR = 'TRADER_IN_PROGRESS_ALLEGED_BR',
  TRADER_ISSUE_FAILED = 'TRADER_ISSUE_FAILED',
  TRADER_ISSUE_RECEIVING = 'TRADER_ISSUE_RECEIVING',
  TRADER_ISSUE_MATCHING = 'TRADER_ISSUE_MATCHING',
  TRADER_COMPLETE_MATCHED = 'TRADER_COMPLETE_MATCHED',
  TRADER_COMPLETE_MATCHED_BR = 'TRADER_COMPLETE_MATCHED_BR',
  TRADER_COMPLETE_PRELIMINARY_MATCHED_BR = 'TRADER_COMPLETE_PRELIMINARY_MATCHED_BR',
  TRADER_COMPLETE_CANCELLED = 'TRADER_COMPLETE_CANCELLED',
  TRADER_COMPLETE_TERMINATED = 'TRADER_COMPLETE_TERMINATED',
  BROKER_IN_PROGRESS_PENDING = 'BROKER_IN_PROGRESS_PENDING',
  BROKER_IN_PROGRESS_ALLEGED = 'BROKER_IN_PROGRESS_ALLEGED',
  BROKER_ISSUE_FAILED = 'BROKER_ISSUE_FAILED',
  BROKER_ISSUE_RECEIVING = 'BROKER_ISSUE_RECEIVING',
  BROKER_ISSUE_MATCHING = 'BROKER_ISSUE_MATCHING',
  BROKER_COMPLETE_MATCHED = 'BROKER_COMPLETE_MATCHED',
  BROKER_COMPLETE_PRELIMINARY_MATCHED = 'BROKER_COMPLETE_PRELIMINARY_MATCHED',
  BROKER_COMPLETE_CANCELLED = 'BROKER_COMPLETE_CANCELLED',
  BROKER_COMPLETE_TERMINATED = 'BROKER_COMPLETE_TERMINATED',
}

export const ecmStatesToLabelMaps = {
  [EcmStatesEnum.TRADER_IN_PROGRESS_PENDING]: 'Pending (CP)',
  [EcmStatesEnum.TRADER_IN_PROGRESS_ALLEGED]: 'Alleged (CP)',
  [EcmStatesEnum.TRADER_IN_PROGRESS_PENDING_BR]: 'Pending (BR)',
  [EcmStatesEnum.TRADER_IN_PROGRESS_ALLEGED_BR]: 'Alleged (BR)',
  [EcmStatesEnum.TRADER_ISSUE_FAILED]: 'Failed',
  [EcmStatesEnum.TRADER_ISSUE_RECEIVING]: 'Receiving',
  [EcmStatesEnum.TRADER_ISSUE_MATCHING]: 'Matching',
  [EcmStatesEnum.TRADER_COMPLETE_MATCHED]: 'Matched',
  [EcmStatesEnum.TRADER_COMPLETE_MATCHED_BR]: 'Matched (BR)',
  [EcmStatesEnum.TRADER_COMPLETE_PRELIMINARY_MATCHED_BR]: 'Preliminary Matched (BR)',
  [EcmStatesEnum.TRADER_COMPLETE_CANCELLED]: 'Cancelled',
  [EcmStatesEnum.TRADER_COMPLETE_TERMINATED]: 'Terminated',
  [EcmStatesEnum.BROKER_IN_PROGRESS_PENDING]: 'Pending',
  [EcmStatesEnum.BROKER_IN_PROGRESS_ALLEGED]: 'Alleged',
  [EcmStatesEnum.BROKER_ISSUE_FAILED]: 'Failed',
  [EcmStatesEnum.BROKER_ISSUE_RECEIVING]: 'Receiving',
  [EcmStatesEnum.BROKER_ISSUE_MATCHING]: 'Matching',
  [EcmStatesEnum.BROKER_COMPLETE_MATCHED]: 'Matched',
  [EcmStatesEnum.BROKER_COMPLETE_PRELIMINARY_MATCHED]: 'Preliminary Matched',
  [EcmStatesEnum.BROKER_COMPLETE_CANCELLED]: 'Cancelled',
  [EcmStatesEnum.BROKER_COMPLETE_TERMINATED]: 'Terminated',
};

export enum EcmDateRange {
  TOTAL = 'TOTAL',
  RANGE2 = 'RANGE2',
  RANGE1 = 'RANGE1',
  BEFORE_TODAY = 'BEFORE_TODAY',
  RANGE3 = 'RANGE3',
  TODAY = 'TODAY',
  RANGE4_7 = 'RANGE4_7',
  RANGE8_30 = 'RANGE8_30',
  RANGE31_90 = 'RANGE31_90',
}

export const ecmDateRangeToLabelMaps = {
  [EcmDateRange.BEFORE_TODAY]: 'Before Today',
  [EcmDateRange.TODAY]: 'Today',
  [EcmDateRange.TOTAL]: 'Total',
  [EcmDateRange.RANGE1]: '1',
  [EcmDateRange.RANGE2]: '2',
  [EcmDateRange.RANGE3]: '3',
  [EcmDateRange.RANGE4_7]: '4-7',
  [EcmDateRange.RANGE8_30]: '8-30',
  [EcmDateRange.RANGE31_90]: '31-90',
};

export interface TerminateDealRequest {
  reason: string;
  confirmationIds: number[];
}

export interface EcmDealItemModel {
  agreement: string;
  auditGroupId: number;
  broker: string;
  brokerFee: string;
  brokerFeeCurrency: string;
  brokerNearMatcherButtonVisible: string;
  brokerState: string;
  brokerTradeId: string;
  buyerParty: string;
  commodity: string;
  counterParty: string;
  counterPartyDisplayName: string;
  counterPartyNearMatcherButtonVisible: boolean;
  counterpartyTradeId: string;
  currency: string;
  deliveryEnd: string;
  deliveryPoint: string;
  deliveryStart: string;
  dfHedgingExemption: string;
  dfReportingParty: string;
  dfRepository: string;
  dfUsi: string;
  documentId: string;
  documentStoreId: string;
  documentType: string;
  documentVersion: number;
  ecmLightAmendmentButtonVisible: boolean;
  ecmLightCancelButtonVisible: boolean;
  effectiveDate: string;
  euReportingBrokerUti: string;
  euReportingUti: string;
  formulaId: string;
  hidden: boolean;
  hideCheckboxVisible: boolean;
  hideReason: string;
  id: number;
  indexCommodity: string;
  market: string;
  matchHash: number;
  notes: Note[];
  partyType: string;
  price: number;
  priceUnit: string;
  receiverId: string;
  sellerParty: string;
  senderId: string;
  state: string;
  submissionDate: string;
  submissionTimestamp: string;
  terminateDealButtonVisible: boolean;
  terminationDate: string;
  terminationReason: string;
  totalContractValue: number;
  totalVolume: number;
  totalVolumeUnit: string;
  tradeDate: string;
  tradeId: string;
  traderName: string;
  transactionType: string;
}
